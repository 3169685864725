import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Card, CardContent, makeStyles, Tab, Tabs, useTheme, withStyles } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';
import { useSelector } from 'react-redux';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import _ from 'lodash';

import LongIcon from 'assets/images/longHighlight.png';
import ShortIcon from 'assets/images/shortHighlight.png';
import Filler from 'assets/images/filler.png';
import { selectFilteredPairsData } from 'redux/selectors';
import { getExchangePrettyName } from 'assets/js/gunbot.helper';
import { getValueColor } from 'assets/js/common';
import Button from 'components/Gunbot/Button';
import FlexAuto from 'components/Gunbot/FlexAuto';

const useStyle = makeStyles(theme => ({
  tabs: {
    minHeight: 'auto',
    float: 'none',
    position: 'relative',
    '& > .MuiTabScrollButton-root': {
      color: 'transparent',
      position: 'absolute',
      width: 18,
      zIndex: 1,
      top: 0,
      bottom: 0,
    },
    '&:hover > .MuiTabScrollButton-root': {
      background: theme.palette.background.paper,
      color: theme.palette.text.primary,
    },
    '& > .MuiTabScrollButton-root:first-child': {
      left: 0,
    },
    '& > .MuiTabScrollButton-root:last-child': {
      right: 0,
    },
  },
  cardContent: {
    height: '100%',
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  tableToolbar: {
    '& > div': {
      minHeight: 34,
      '& > div': {
        position: 'unset',
      },
    },
  },

  pairTableWapper: {
    '& > .MuiPaper-root > div:last-child': {
      height: 'calc(100% - 34px)',
    },
    '& > .MuiPaper-root > div:last-child::-webkit-scrollbar': {
      display: 'block',
    },
    '& th': {
      position: 'sticky',
      top: 0 /* Don't forget this, required for the stickiness */,
      boxShadow: '0 2px 2px -1px rgba(0, 0, 0, 0.4)',
    },
    '& tr': {
      '& > td': {
        paddingTop: 5,
        padding: 0,
        border: 'none',
        paddingBottom: 3,
        lineHeight: 1,
        height: 28,
      },
      '& > th:first-child': {
        paddingLeft: '12px !important',
      },
      '& > th:last-child': {
        paddingRight: '5px !important',
      },
      '& > td:first-child': {
        paddingLeft: 5,
      },
      '& > td:last-child': {
        paddingRight: 5,
      },
      '&:hover > td:not([colspan])': {
        cursor: 'pointer',
        backgroundColor: 'rgba(255,255,255,0.2)',
      },
    },
  },
}));

const MarketsTable = ({ selectedSymbol = '', setSelectedSymbol, history, symbols }) => {
  const theme = useTheme();
  const classes = useStyle();
  const pairsData = useSelector(selectFilteredPairsData(filterPairData, false) || {}, _.isEqual);
  const config = useSelector(state => state.settings.config, _.isEqual);

  const [, selectedExchange] = selectedSymbol.split(' / ');

  const { exchanges, marketsData } = useMemo(() => {
    const marketsData = {};

    _.each(pairsData, (pairData, key) => {
        const [exchange, pair] = key.split('/');

        if (!marketsData[exchange] && config?.pairs[exchange] && Object.keys(config?.pairs[exchange] || {}).some(item => config?.pairs?.[exchange][item].enabled)) {
            marketsData[exchange] = [];
        }

        const symbolIsActive = symbols.some(symbol => 
            symbol.pair === pair && 
            symbol.exchange === exchange && 
            (pairsData[key]?.candlesClose || []).length > 0 &&
            config?.pairs?.[exchange][pair] && config?.pairs?.[exchange][pair].enabled
        );

        if (symbolIsActive) {
            marketsData[exchange].push(pairData);
        }
    });

    return { 
        marketsData, 
        exchanges: Object.keys(marketsData) 
    };
}, [pairsData, config]);


  const [tabIndex, setTabIndex] = useState(0);

  const handleChangeMarket = useCallback((_, tab) => setTabIndex(tab), []);

  /*
  const handlePairSelect = useCallback(
    (_, { key }) => {
      if (!window.tvWidget) return;
      const [exchange, pair] = key.split('/');
      const symbolName = `${pair} / ${exchange}`;
      if (symbolName != selectedSymbol) {
        setSelectedSymbol(symbolName);
      }
    },
    [setSelectedSymbol, selectedSymbol],
  );
  */

  const handlePairSelect = function (_, { key }) {
    if (!window.tvWidget) return;
    const [exchange, pair] = key.split('/');
    const symbolName = `${pair} / ${exchange.toUpperCase()}`;
    const symbolObject = {pair, exchange, name: symbolName}
    if (symbolName != selectedSymbol) {
      //setSelectedSymbol(symbolName);
      window.setSymbol(symbolObject)
    }
  };

  useEffect(() => {
    let index = exchanges.indexOf(selectedExchange);
    if (index !== -1) {
      setTabIndex(index);
    }
  }, [selectedExchange]);

  const marketData = useMemo(() => {
    return marketsData[exchanges[tabIndex]] || [];
  }, [exchanges[tabIndex], marketsData]);

  const columns = useMemo(() => {
    return [
      {
        title: 'Pair ' + '(' + marketData.length + ')',
        field: 'quote',
        // sort normal pairs on key, sort FTX perpetuals on quote in key
        customSort: (a, b) => {
          const pairA = a.key.includes('PERP') ? a.key.split('-')[0] : a.key;
          const pairB = b.key.includes('PERP') ? b.key.split('-')[0] : b.key;
          if (pairA < pairB) {
            return -1;
          }
          if (pairA > pairB) {
            return 1;
          }

          return 0;
        },
        render: rowData => {
          let pairName = rowData['base'] + '-' + rowData['quote'];
          let selectedStyle = {};
          
          if (
            rowData.key.includes('LONG') &&
            (rowData.key.includes('bybit') ||
              rowData.key.includes('okex5') ||
              rowData.key.includes('okgunbot') ||
              rowData.key.includes('bitget')) &&
            rowData.isMargin === true
          ) {
            pairName = rowData['base'] + '-' + rowData['quote'] + '-LONG';
          } else if (
            rowData.key.includes('SHORT') &&
            (rowData.key.includes('bybit') ||
              rowData.key.includes('okex5') ||
              rowData.key.includes('okgunbot') ||
              rowData.key.includes('bitget')) &&
            rowData.isMargin === true
          ) {
            pairName = rowData['base'] + '-' + rowData['quote'] + '-SHORT';
          }

          if (selectedSymbol === `${pairName} / ${rowData.exchange.toUpperCase()}`) {
            selectedStyle = {
              color: '#f0b90b',
            };
          }

          return (
            <Box
              display={'flex'}
              alignItems={'center'}
              style={{ minWidth: '18ch', maxWidth: '22ch', ...selectedStyle }}
            >
              {rowData['direction'] === 'long' ? (
                <img
                  src={LongIcon}
                  style={{ width: '100%', minWidth: '4px', maxWidth: '4px', marginRight: '4px' }}
                  alt="Long"
                />
              ) : rowData['direction'] === 'short' ? (
                <img
                  src={ShortIcon}
                  style={{ width: '100%', minWidth: '4px', maxWidth: '4px', marginRight: '4px' }}
                  alt="Short"
                />
              ) : (
                <img src={Filler} style={{ width: '100%', minWidth: '4px', maxWidth: '4px', marginRight: '4px' }} />
              )}
              <Box
                component={'span'}
                className={classes.quoteCurrency}
                style={{ minWidth: '18ch', maxWidth: '22ch', ...selectedStyle }}
              >
                {pairName}
              </Box>
            </Box>
          );
        },
      },
      {
        title: '',
        field: 'sparkline',
        cellStyle: {
          paddingLeft: '0px',
          paddingRight: '0px',
          paddingTop: '0px',
          paddingBottom: '0px',
          marginRight: '0px',
          marginLeft: '-30px',
        },

        type: 'numeric',
        render: rowData => {
          return (
            <Sparklines
              data={rowData['candlesClose']}
              width={100}
              height={30}
              style={{ margin: '0px', padding: '0px', paddingTop: '5px', height: 20 }}
            >
              <SparklinesLine style={{ stroke: grey[200], strokeWidth: 1, fill: grey[200] }} />
              <SparklinesSpots size={2} style={{ stroke: grey[200], strokeWidth: 2, fill: 'white' }} />
            </Sparklines>
          );
        },
      },
      {
        title: 'ROE',
        field: 'ROE',
        cellStyle: {
          textAlign: 'right',
        },
        type: 'numeric',
        customSort: (a, b) => {
          const pairA = a.ROE.includes('%') ? parseFloat(a.ROE.split('%')[0]) : -999999;
          const pairB = b.ROE.includes('%') ? parseFloat(b.ROE.split('%')[0]) : -999999;
          if (pairA < pairB) {
            return -1;
          }
          if (pairA > pairB) {
            return 1;
          }

          return 0;
        },
        render: rowData => {
          let hedgeExchange = false;
          let color = getValueColor(rowData['ROE']);
          return <Box color={color}>{rowData['ROE']}</Box>;
        },
      },
    ];
  }, [marketData.length, selectedSymbol]);

  return (
    <Box height="100%">
      <Card style={{ height: '100%' }}>
        <CardContent className={classes.cardContent}>
          <Box mb={1}>
            <Tabs
              value={tabIndex}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
              textColor="primary"
              className={classes.tabs}
              onChange={handleChangeMarket}
            >
              {exchanges.map(exchange => (
                <StyledTab label={getExchangePrettyName(exchange)} key={exchange} />
              ))}
            </Tabs>
          </Box>
          <FlexAuto flex={'auto'} className={classes.pairTableWapper}>
            <MaterialTable
              columns={columns}
              data={marketData}
              style={{ boxShadow: 'none', fontSize: 'smaller', position: 'relative', height: '100%' }}
              options={{
                paging: false,
                maxBodyHeight: 'calc(100vh - 0px)',
                headerStyle: {
                  padding: 0,
                  color: theme.typography.subtitle2.color,
                  border: 'none',
                  fontSize: 12,
                  paddingBottom: 3,
                },
                overflowY: 'visible',
                searchFieldStyle: {
                  position: 'absolute',
                  width: 'unset',
                  paddingLeft: 8,
                  right: 0,
                  left: 0,
                  top: 0,
                  fontSize: 13,
                },
                showTitle: false,
              }}
              onRowClick={handlePairSelect}
              components={{
                Toolbar: props => (
                  <div className={classes.tableToolbar}>
                    <MTableToolbar {...props} />
                  </div>
                ),
              }}
            />
          </FlexAuto>
          <Box px={2} mt={2}>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              startIcon={<Add />}
              onClick={() => history.push('/trading-settings')}
            >
              Add pair
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default MarketsTable;

const StyledTab = withStyles({
  root: { minHeight: 'auto', minWidth: 'auto', textTransform: 'none', padding: '4px 8px' },
})(Tab);

const filterPairData = (pairData, key) => {
  let [exchange, pair = 'Unknown'] = key.split('/');
  let [base, quote] = pair.split('-');

  let aboveMvts = false;
  let displayROE = 'loading';
  let side = 'none';
  let close = [];
  let ismargin = false;

  if (pairData.whatstrat.IS_MARGIN_STRAT === false) {
    displayROE = pairData.precalculatedSpotRoe || 'loading';
  } else if (pairData.whatstrat.IS_MARGIN_STRAT === true) {
    displayROE = pairData.precalculatedFuturesRoe || 'loading';
  }

  if (
    !_.isNil(pairData.Bid) &&
    !_.isNil(pairData.quoteBalance) &&
    !_.isNil(pairData.whatstrat.MIN_VOLUME_TO_SELL) &&
    !_.isNil(pairData.whatstrat.IS_MARGIN_STRAT) &&
    !_.isNil(pairData.ABP) &&
    !_.isNil(pairData.candlesclose) &&
    !_.isNil(pairData.reversal)
  ) {
    aboveMvts =
      pairData.Bid * (pairData.quoteBalance - parseFloat(pairData.whatstrat.KEEP_QUOTE)) >
        pairData.whatstrat.MIN_VOLUME_TO_SELL || pairData.reversal;

    if (pairData.whatstrat.IS_MARGIN_STRAT === true) {
      ismargin = true;
      if (pairData.currentQty < 0) {
        side = 'short';
      } else if (pairData.currentQty > 0) {
        side = 'long';
      }
    } else if (pairData.whatstrat.IS_MARGIN_STRAT === false && aboveMvts === true) {
      side = 'long';
    }

    close = pairData.candlesclose
  }

  return {
    key,
    ROE: displayROE,
    base,
    quote,
    direction: side,
    candlesClose: close,
    isMargin: ismargin,
    exchange,
  };
};
